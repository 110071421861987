import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/punpun.jpg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaBehance, FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              UNE COURTE <span className="purple"> PRESENTATION </span> DE MOI
              MÊME.
            </h1>
            <p className="home-about-body">
              Doté d'un esprit créatif, j'aime apprendre des autres et partager
              ma passion pour le design
              <br />
              <br />
              J'utilise régulièrement
              <i>
                <b className="purple"> Figma, HTML/CSS et JavaScript. </b>
              </i>
              <br />
              <br />
              Mon domaine d'intérêt est la construction de nouveaux&nbsp;
              <i>
                <b className="purple">Prototype de site ou d'application </b>
              </i>
              <br />
              <br />
              Mais j'ai aussi un intérêt, dans le développement web, la création
              de design 2D et le motion design avec{" "}
              <b className="purple">Figma/Krita</b> et
              <i>
                <b className="purple"> la suite Adobe</b>.
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Retrouve moi sur</h1>
            <p>
              Sentez-vous libre de me <span className="purple">contacter </span>
              à tout moment !
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/AkiraKs"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.behance.net/adrienlauriere1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaBehance />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/adrien-lauriere-6b1424259"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
