import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import csm from "../../Assets/Projects/csm.png";
import bunbun from "../../Assets/1.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mes <strong className="purple"> travaux </strong> récents
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={csm}
              isBlog={false}
              title="Application fictive"
              description="Une application fictive sur l'univers du manga Chainsaw man version mobile uniquement, cette application à pour but d'aider un groupe de chasseur de démon appelé 'Devil hunters'"
              ghLink="https://www.figma.com/file/hXkRBuUURvHWp7XwhgJ24B/Lauriere-Adrien?type=design&node-id=0%3A1&mode=design&t=4tYavbmisv21B4SN-1"
              demoLink="https://www.figma.com/proto/hXkRBuUURvHWp7XwhgJ24B/Lauriere-Adrien?type=design&node-id=234-3336&t=4tYavbmisv21B4SN-0&scaling=scale-down&page-id=1%3A5&starting-point-node-id=234%3A3336"
            />
          </Col>{" "}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bunbun}
              isBlog={false}
              title="Site Web Coffee shop"
              description="BunBun est un coffee shop qui va bientôt ouvrir dans le centre de Limoges. 
              Passionné par la culture et la nourriture japonaise, Axel, propriétaire de BunBun, souhaite proposer chaque jour des desserts typiquement japonais, des plats de street food japonaise sans oublier d’excellents cafés et thés à déguster toute la journée. Le tout avec des ingrédients locaux, bio et de saison."
              ghLink="https://www.figma.com/file/TW6JBIAq9lKJzDzPxREwYJ/Lauriere-Adrien?type=design&mode=design&t=hZnFUGXoWFLDlpdb-1"
              demoLink="https://www.figma.com/proto/TW6JBIAq9lKJzDzPxREwYJ/Lauriere-Adrien?type=design&t=hZnFUGXoWFLDlpdb-0&scaling=min-zoom&page-id=0%3A1&node-id=1-11"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
