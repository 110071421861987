import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Salut, je suis <span className="purple">Lauriere Adrien </span>
            je viens de <span className="purple"> Périgueux, France</span>,
            <br />
            et je suis actuellement en BUT MMI (Métiers du Multimédia et de
            l'Internet) à Limoges.
            <br />
            <br />
            Mon parcours dans le domaine du{" "}
            <span className="purple">multimédia</span> m'a permis de plonger
            dans l'univers captivant de la{" "}
            <span className="purple">création visuelle</span>. Passionné par des
            disciplines telles que le{" "}
            <span className="purple">graphisme et le Web Design</span>, je
            m'épanouis dans l'exploration de nouvelles techniques et la
            conceptualisation de{" "}
            <span className="purple">designs innovants</span>.
            <br />
            <br />
            En dehors du design, voici certaines autres activités que j'aime
            faire !
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Les jeux vidéos
            </li>
            <li className="about-activity">
              <ImPointRight /> Le tatouage
            </li>
            <li className="about-activity">
              <ImPointRight /> La musique
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
